<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search attendances')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="white"
              @click="getAttendances()"
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh table") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="attendanceDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="attendanceDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              in
              solo
            ></v-text-field>
          </template>
          <v-date-picker v-model="attendanceDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(attendanceDate);
                dateFilter();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>

    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-layout row wrap justify-center v-if="employees">
        <v-flex xs12 sm6 md6 lg6 class="text-left">
          <v-avatar size="40">
            <v-img :src="employees.avatar" style="cursor: pointer">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    width="5"
                    indeterminate
                    :color="$store.state.primaryColor"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <span class="text-h5 font-weight-bold">
            {{
              employees.names.first +
              " " +
              employees.names.middle +
              " " +
              employees.names.last
            }}</span
          >
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
          <v-btn
            v-if="userAccess == 1"
            :color="$store.state.primaryColor"
            outlined
            small
            @click="newAttendanceEntry(employeeId)"
          >
            {{ $t("new attendance") }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 md12 lg12>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="filteredAttendances"
            :search="search"
            :sort-by.sync="sortByDate"
            :sort-desc.sync="sortDesc"
            :items-per-page="20"
          >
            <template v-slot:[`item.serial`]="{ item }">
              <v-avatar size="25" :color="$store.state.primaryColor">
                <span class="caption white--text">{{ item.serial }}</span>
              </v-avatar>
            </template>
            <template v-slot:[`item.dateTimeIn`]="{ item }">
              {{ formatDate(item.dateTimeIn) }}
            </template>
            <template v-slot:[`item.dateTimeOut`]="{ item }">
              {{ formatDate(item.dateTimeOut) }}
            </template>
            <template v-slot:[`item.hours`]="{ item }">
              <v-btn small text dark left :color="$store.state.primaryColor">
                {{
                  calculateNumberOfHours(item.dateTimeIn, item.dateTimeOut)
                }}</v-btn
              >
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import db from "@/plugins/fb";
import { format, differenceInHours } from "date-fns";
import PleaseWait from "@/components/templates/PleaseWait";

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    loading: true,
    search: "",
    pleaseWait: true,
    sortDesc: true,
    modal: false,
    sortByDate: "dateTimeIn",
    filteredAttendances: [],
    attendances: [],
    serial: 0,
    employees: [],
    attendanceDate: format(new Date(), "yyyy-MM-dd"),
  }),

  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "serial",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("entry time"),
          value: "dateTimeIn",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("exit time"),
          value: "dateTimeOut",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("hours"),
          value: "hours",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  created() {
    this.getAttendances();
    this.getEmployeeDetails();
    this.checkAccessLevel();
  },

  methods: {
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isEmployee
        ? 2
        : 3;
    },

    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.employees = doc.data();
          this.loading = false;
          this.employeeId = this.employees.employeeId;
          this.leave.name.first = this.employees.names.first;
          this.leave.name.middle = this.employees.names.middle;
          this.leave.name.last = this.employees.names.last;

          this.leave.avatar = this.employees.avatar;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAttendances() {
      this.loading = true;
      this.attendances = [];
      this.serial = 1;
      db.collection("attendances")
        .where("employeeId", "==", this.$route.params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.attendances.push({
              id: doc.id,
              serial: this.serial++,
              ...doc.data(),
            });
          });
          this.pleaseWait = false;
          this.filteredAttendances = this.attendances;

          this.loading = false;
        });
    },

    dateFilter() {
      let filtered = this.attendances.filter(
        (item) =>
          format(item.dateTimeIn.toDate(), "yyyy-MM-dd") == this.attendanceDate
      );
      this.filteredAttendances = filtered;
    },

    calculateNumberOfHours(dateTimeIn, dateTimeOut) {
      const result = differenceInHours(
        dateTimeOut.toDate(),
        dateTimeIn.toDate()
      );
      return result;
    },
    formatDate(val) {
      return format(val.toDate(), "dd MMMM, yyyy HH:mm:a");
    },
    newAttendanceEntry(id) {
      this.$router.push({ name: "CreateAttendance", params: { id: id } });
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
