var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"outlined":"","small":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("back")))])],1)],1),_c('v-spacer')],1),_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"text-right py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","label":_vm.$t('search attendances')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){return _vm.getAttendances()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("refresh table")))])])],1),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.attendanceDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.attendanceDate=$event},"update:return-value":function($event){_vm.attendanceDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","dense":"","in":"","solo":""},model:{value:(_vm.attendanceDate),callback:function ($$v) {_vm.attendanceDate=$$v},expression:"attendanceDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.attendanceDate),callback:function ($$v) {_vm.attendanceDate=$$v},expression:"attendanceDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.attendanceDate);
              _vm.dateFilter();}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1)],1),(_vm.pleaseWait)?_c('div',{staticClass:"mt-3"},[_c('PleaseWait')],1):_c('div',[(_vm.employees)?_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"text-left",attrs:{"xs12":"","sm6":"","md6":"","lg6":""}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.employees.avatar},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"width":"5","indeterminate":"","color":_vm.$store.state.primaryColor}})],1)]},proxy:true}],null,false,902527807)})],1),_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.employees.names.first + " " + _vm.employees.names.middle + " " + _vm.employees.names.last))])],1),_c('v-spacer'),_c('v-flex',{staticClass:"py-2 text-right",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[(_vm.userAccess == 1)?_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"outlined":"","small":""},on:{"click":function($event){return _vm.newAttendanceEntry(_vm.employeeId)}}},[_vm._v(" "+_vm._s(_vm.$t("new attendance"))+" ")]):_vm._e()],1)],1):_vm._e(),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.filteredAttendances,"search":_vm.search,"sort-by":_vm.sortByDate,"sort-desc":_vm.sortDesc,"items-per-page":20},on:{"update:sortBy":function($event){_vm.sortByDate=$event},"update:sort-by":function($event){_vm.sortByDate=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.serial",fn:function(ref){
              var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"25","color":_vm.$store.state.primaryColor}},[_c('span',{staticClass:"caption white--text"},[_vm._v(_vm._s(item.serial))])])]}},{key:"item.dateTimeIn",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateTimeIn))+" ")]}},{key:"item.dateTimeOut",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateTimeOut))+" ")]}},{key:"item.hours",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","dark":"","left":"","color":_vm.$store.state.primaryColor}},[_vm._v(" "+_vm._s(_vm.calculateNumberOfHours(item.dateTimeIn, item.dateTimeOut)))])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }